import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

export default class RedirectHelper extends PureComponent{
    constructor(props){
        super(props);

        this.state = {
            key: null,
            props: null
        };

        this.go = this.go.bind(this);
    }

    go(to){
        if(typeof to === 'number'){
            return window.history.go(to);
        }
        if(to){
            let props = {};
            if(typeof to === 'string'){
                Object.assign(props, { to, push: ('/404' === to)});
            } else {
                props = to;
            }
            let key = '' + (new Date().getTime() + Math.random());
            this.setState({ key, props });
        }
    }

    render(){
        let { key, props } = this.state;
        return(
            props ? <Redirect key={key} push { ...props} /> : null
        );
    }
}