import api from '../utils/api';

const AUTH_LOGIN = '/auth/login';
const GET_PROFILE = '/auth/profile'; //GET
const CHANGE_PASSWORD = '/auth/profile'; //POST
const UPD_PROFILE = '/auth/profile'; //PUT

class AuthService extends api {
    login = ({ username, password }) => {
        return this.post(AUTH_LOGIN, { username, password })
    }
    getProfile = () => {
        return this.get(GET_PROFILE)
    }
    changePassword = (param) => {
        return this.post(CHANGE_PASSWORD, param)
    }
    updateProfile = (param) => {
        return this.put(UPD_PROFILE, param);
    }
}

export default new AuthService();