import React from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


function Loading() {
    return (
        <div className="loading-page">
            <BeatLoader
                css={override}
                 size={15}
                color={"#36D7B7"}
                loading={true}
                // height={15}
                // width={5}
                // radius={2}
                margin={2}
            />
        </div>
    );
}

export default Loading;