const USER_AUTH_KEY = 'auth'; 
const USER_PROFILE_KEY = 'profile'; 
const StorageService = {
    storeAuthData(data) {
        let authData = this.getAuthData();
        authData = Object.assign(authData || {}, data);
        localStorage.setItem(USER_AUTH_KEY, JSON.stringify(authData));
    },

    getAuthData() {
        let authData = localStorage.getItem(USER_AUTH_KEY);
        if(authData) return JSON.parse(authData);
        return null;
    },

    deleteAuthData(){
        localStorage.removeItem(USER_AUTH_KEY)
    },

    isAuthenticated() {
        return localStorage[USER_AUTH_KEY];
    },

    getAccessToken(){
        let authData = this.getAuthData();
        if(!authData) return '';
        return authData['accessToken'];
    },

    getProfileStorage() {
        let profile = localStorage.getItem(USER_PROFILE_KEY);
        if(profile) return JSON.parse(profile);
        return null;
    },
    deleteProfileStorage(){
        localStorage.removeItem(USER_PROFILE_KEY)
    },
    storeProfile(data) {
        localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(data));
    },
}

export default StorageService;