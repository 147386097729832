export const POSITION_FETCH = 'POSITION_FETCH';
export const POSITION_FETCH_SUCCESS = 'POSITION_FETCH_SUCCESS';
export const POSITION_FETCH_FAILED = 'POSITION_FETCH_FAILED';

export const POSITION_UPD_STATUS = 'POSITION_UPD_STATUS';
export const POSITION_UPD_STATUS_SUCCESS = 'POSITION_UPD_STATUS_SUCCESS';
export const POSITION_UPD_STATUS_FAILED = 'POSITION_UPD_STATUS_FAILED';

export const POSITION_DEL = 'POSITION_DEL';
export const POSITION_DEL_SUCCESS = 'POSITION_DEL_SUCCESS';
export const POSITION_DEL_FAILED = 'POSITION_DEL_FAILED';


export const POSITION_RESET_ERROR = 'POSITION_RESET_ERROR';