import {
    POSITION_FETCH,
    POSITION_FETCH_SUCCESS,
    POSITION_FETCH_FAILED,
    POSITION_UPD_STATUS_SUCCESS,
    POSITION_UPD_STATUS_FAILED,
    POSITION_DEL_SUCCESS,
    POSITION_DEL_FAILED,
    POSITION_RESET_ERROR
} from '../constants/position.constant';

const initialState = {
    isLoading: false,
    list: {
        data: [],
        total: 0
    },
    errMsg: null
}

const positionReducer = (state = initialState, action) => {
    switch (action.type) {
        case POSITION_FETCH:
            return {
                ...state,
                isLoading: true,
                errMsg: null
            }
        case POSITION_FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: action.result,
                errMsg: null
            }
        case POSITION_FETCH_FAILED:
            return {
                ...state,
                isLoading: false,
                errMsg: action.errMsg
            }
        case POSITION_UPD_STATUS_SUCCESS:
            return {
                ...state,
                list: {
                    data: action.data,
                    total: state.list.total
                }
            }
        case POSITION_UPD_STATUS_FAILED:
            return {
                ...state,
                errMsg: action.errMsg
            }
        case POSITION_DEL_SUCCESS:
            return {
                ...state,
                list: {
                    data: action.data,
                    total: state.list.total
                }
            }
        case POSITION_DEL_FAILED:
            return {
                ...state,
                errMsg: action.errMsg
            }
        case POSITION_RESET_ERROR:
            return {
                ...state,
                errMsg: null
            }
        default:
            return state;
    }
}

export default positionReducer;