import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS
} from '../constants/login.constant';

import { put, takeEvery, all, call,takeLatest } from 'redux-saga/effects';
import loginService from '../../services/auth.service';
import storageService from '../../services/storage.service';


function* login(action) {
    try {
        const payload = yield call(loginService.login, action.payload);
        //Set localstorage
        storageService.storeAuthData(payload);
        //Get Profile
        const profile = yield call(loginService.getProfile);
        storageService.storeProfile(profile);
        
        yield put({ type: LOGIN_SUCCESS});
        
    } catch (error) {
        yield put({ type: LOGIN_FAILED,  error: typeof error  === 'object' && error.s == 'error' ? error.errmsg : error});
    }
}

function* watchLogin() {
    yield takeLatest(LOGIN, login);
}

export default function* loginSaga() {
    yield all([
        watchLogin(),
    ]);
}


