import api from '../utils/api';

const POSITION_SEARCH = '/positions'; //GET
const POSITION_INS = '/positions'; //POST
const POSITION_UPD = '/positions/:id'; //PUT
const POSITION_DEL = '/positions/:id'; //DELETE
const POSITION_SEL = '/positions/:id'; //GET
const POSITION_UPD_STATUS = '/positions/:id/status'; //PUT

class PositionService extends api {
    search = (param) => {
        return this.get(POSITION_SEARCH, param);
    }
    changeStatus = ({ positionid, status }) => {
        return this.put(POSITION_UPD_STATUS.replace(':id', positionid), { status: status ? 1 : 0 })
    }
    insert = (param) => {
        return this.post(POSITION_INS, param);
    }
    update = (param) => {
        return this.put(POSITION_UPD.replace(':id', param.positionid), param );
    }
    detail = (positionid) => {
        return this.get(POSITION_SEL.replace(':id', positionid));
    }
    _delete = (positionid) => {
        return this.delete(POSITION_DEL.replace(':id', positionid));
    }
    insertUpdate =  param => {
        if(param.positionid > 0){
            return this.put(POSITION_UPD.replace(':id', param.positionid), param );
        }
        return this.post(POSITION_INS, param);
    }
}

export default new PositionService();