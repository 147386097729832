import { put, takeEvery, all, call, takeLatest, fork, select } from 'redux-saga/effects';
import {
    POSITION_FETCH,
    POSITION_FETCH_SUCCESS,
    POSITION_FETCH_FAILED,
    POSITION_UPD_STATUS,
    POSITION_UPD_STATUS_SUCCESS,
    POSITION_UPD_STATUS_FAILED,
    POSITION_DEL,
    POSITION_DEL_SUCCESS,
    POSITION_DEL_FAILED
} from '../constants/position.constant';

import positionService from '../../services/position.service';


function* fetchPosition(action) {
    try {
        const res = yield call(positionService.search, action.payload);
        const { list: data, total } = res;
        yield put({ type: POSITION_FETCH_SUCCESS, result: { data, total } });

    } catch (error) {
        yield put({ type: POSITION_FETCH_FAILED, errMsg: typeof error === 'object' && error.s == 'error' ? error.errmsg : error });
    }
}

function* updateStatus(action) {
    try {
        //Call API
        const res = yield call(positionService.changeStatus, action.payload);
        //Get Reducer
        let data = yield select(state => state.positionReducer.list.data);
        const { payload: { index, positionid, status } } = action;
        data[index].isactive = status;
        yield put({ type: POSITION_UPD_STATUS_SUCCESS, data });
    } catch (error) {
        yield put({ type: POSITION_UPD_STATUS_FAILED, errMsg: typeof error === 'object' && error.s == 'error' ? error.errmsg : error });
    }
}

function* _delete(action) {
    try {
        //Call API
        const { payload: { index, positionid } } = action;
        const res = yield call(positionService._delete, positionid);
        let data = yield select(state => state.positionReducer.list.data);
        let cloneData = [...data];
        cloneData.splice(index, 1);
        yield put({ type: POSITION_DEL_SUCCESS, data: cloneData });

    } catch (error) {
        yield put({ type: POSITION_DEL_FAILED, errMsg: typeof error === 'object' && error.s == 'error' ? error.errmsg : error });
    }
}

function* watchPosition() {
    yield takeLatest(POSITION_FETCH, fetchPosition);
    yield takeLatest(POSITION_UPD_STATUS, updateStatus);
    yield takeLatest(POSITION_DEL, _delete);
}


export default function* positionSaga() {
    yield all([
        watchPosition(),
    ]);
}

