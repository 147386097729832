
Object.assign(window, {
    // create ref shortcut
    _$g: {
      userAuth: null,
      rdr: function() {
        alert('[rdr] Not yet ready!');
      }
    },
  });
  export default global;
  