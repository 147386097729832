import axios from 'axios';
import StorageService from '../services/storage.service';


class API {

    static API_URL_ROOT = (process.env.REACT_APP_API_URL_ROOT || 'http://localhost:5000/');
    static API_AUTH_REFRESH_TOKEN = 'auth/o/token';


    static buildApiUri(api='api/v1/') {
        return `${_static.API_URL_ROOT}${api}`;
    }

    refreshAuthData(response){
        let self = this;
        return new Promise((resolve, reject) => {
            const funcNG = (err) => {
                if (err) {
                    //return reject(new Error(err));
                    return reject(err);
                }
            };
            if (response) {
                let { status } = response || {};
                if (401 === status) {
                    let authData = {};
                    authData = StorageService.getAuthData();
                    if ((authData && !Object.keys(authData).length) || !authData) {
                        return funcNG('Vui lòng kiểm tra lại kết nối.');
                    }
                    let { refreshToken } = authData;
                    return resolve(
                        self.post(_static.API_AUTH_REFRESH_TOKEN, { refreshToken })
                            .then(data => {
                                StorageService.storeAuthData(data);
                                return data;
                            })
                            .catch(err => funcNG())
                    );
                }
                else if(403 === status) {
                    reject('Invalid token.');
                }
                else reject(response);
            }
            resolve();
        });
    }

    _request(_config) {
        let self = this;

        let config = Object.assign({ baseURL: _static.buildApiUri() },
            _config = _config || {},{
            headers: {
                Authorization: `Bearer ${StorageService.getAccessToken()}`
            }
        });

        let incomming = new Promise((resolve, reject) => {
            return axios(config)
                .then(response => {
                    if (config.responseType && config.responseType === 'blob') {
                        return response.data;
                    };
                    let { s, d: apiData, errmsg } = response.data;
                    if(errmsg === 'Invalid Access Token' || errmsg === 'Token is invalid') {
                        window._$g.rdr('/logout');
                    }
                    else if(s === 'error') return reject(errmsg);
                    return apiData;
                })
                .then(resolve)
                .catch(err => {
                    let { response } = err;
                    if (!response) {
                        return reject('Vui lòng kiểm tra lại kết nối.')
                        //throw err;
                    }
                    let { data: apiData, status } = response;
                    apiData = Object.assign(apiData, {status});
                    
                     // Network error
                     if(!apiData.status) return reject('Vui lòng kiểm tra lại kết nối.')
                    // Case: token expired?
                    this.refreshAuthData(apiData)
                        .then(data => {
                            // Case: no need to refresh token
                            if (!data) {
                                //window._$g.rdr('/logout');
                                return reject(apiData);
                            }
                            // Case: refresh token OK --> auto recall
                            resolve(self._request(config));
                        })
                         // Case: refresh token has failed
                        .catch(reject);
                });
        });
        return incomming;
    }

    get(url, params, _config) {
        let config = Object.assign({
            url,
            method: 'get',
            params
        }, _config || {});
        return this._request(config);
    }

    post(url, data, _config) {
        let config = Object.assign({
            url,
            method: 'post',
            data
        }, _config || {});
        return this._request(config);
    }

    put(url, data, _config) {
        let config = Object.assign({
            url,
            method: 'put',
            data
        }, _config || {});
        return this._request(config);
    }

    patch(url, data, _config) {
        let config = Object.assign({
            url,
            method: 'patch',
            data
        }, _config || {});
        return this._request(config);
    }

    delete(url, params, _config) {
        let config = Object.assign({
            url,
            method: 'delete',
            params
        }, _config || {});
        return this._request(config);
    }
}
const _static = API;


export default API;