import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS
} from '../constants/login.constant';

const initialState = {
    isLoading: false,
    success: false,
    errmsg: ''
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLoading: true,
                errmsg: ''
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                success: true,
                isLoading: false,
                errmsg: ''
            }
        case LOGIN_FAILED:
            return {
                ...state,
                errmsg: action.error,
                isLoading: false,
                success: false
            }
        default:
            return state;
    }
}

export default loginReducer;