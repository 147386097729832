import { combineReducers } from 'redux';
import loginReducer from './reducers/login.reducer';
import positionReducer from './reducers/position.reducer';

// const rootReducer = combineReducers({
//     loginReducer,
//     positionReducer
// });

const appReducer = combineReducers({
    loginReducer,
    positionReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}
export default rootReducer;