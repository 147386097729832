import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

// import Page404 from './pages/404';
// import Page500 from './pages/500';
// import LoginPage from './pages/LoginPage';
import Redirect from './common/Redirect';
// import MainPage from './pages/MainPage';
// import LogoutPage from './pages/LogoutPage';
import Loading from './common/Loading';

const Page404 = React.lazy(() => import('./pages/404'));
const Page500 = React.lazy(() => import('./pages/500'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const MainPage = React.lazy(() => import('./pages/MainPage'));
const LogoutPage = React.lazy(() => import('./pages/LogoutPage'));


function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Redirect ref={ref => (ref && (window._$g.rdr = (ref && ref.go)))} />
        <Switch>
          <Route
            exact
            path="/login"
            name={"Đăng nhập"}
            component={LoginPage}
          />
          <Route
            exact
            path="/logout"
            name={"Đăng Xuất"}
            component={LogoutPage}
          />
          <Route
            exact
            path="/404"
            name={"Page 404"}
            component={Page404}
          />
          <Route
            exact
            path="/500/:error?"
            name="Page 500"
            component={Page500}
          />
          <Route
            path="/"
            name="Dashboard"
            component={MainPage}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;

// export default class App extends React.Component {
//   constructor(props) {
//     super(props);
//   }
//   render() {
//     return (
//       <Router>
//         <Redirect ref={ref => (ref && (window._$g.rdr = (ref && ref.go)))} />
//         <Switch>
//           <Route
//             exact
//             path="/login"
//             name={"Đăng nhập"}
//             component={LoginPage}
//           />
//           <Route
//             exact
//             path="/404"
//             name={"Page 404"}
//             component={Page404}
//           />
//           <Route
//             exact
//             path="/500/:error?"
//             name="Page 500"
//             component={Page500}
//           />
//           <Route
//             path="/"
//             name="Dashboard"
//             component={MainPage}
//           />
//         </Switch>

//       </Router>
//     );
//   }
// }


